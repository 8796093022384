<template>
    <b-modal id="city-edit-modal" title="City Edit Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 v-if="allowEdit" class="m-0">Edit City</h2>
        <h2 v-else class="m-0">View City</h2>
      </template>
      <validation-observer ref="cityEditFormValidation">
        <b-form @submit.prevent>
            <b-row>
              <b-col md="12" sm="12" lg="12" xl="12">
                  <b-form-group label-for="minimumCheckIn">
                    <template #label
                      ><span class="text-white font-weight-bolder">
                        Minimum CheckIn Date
                      </span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="unavailableFromField"
                      :rules="{ required: true }"
                    >
                      <b-input-group>
                        <b-form-input
                          id="minimumCheckIn"
                          v-model="minimumCheckIn"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="minimumCheckInDatePicker"
                            v-model="minimumCheckIn"
                            button-variant="primary"
                            :min="minDate"
                            button-only
                            right
                            variant="primary"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <template #label>
                  Name <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Name"
                      :rules="{ required, regex: /^[0-9A-Z-()#&''\s]*$/i }">
                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                      placeholder="Name" :disabled="!allowEdit"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Category" rules="required">
              <b-form-group label-for="category" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Category <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="category" inputId="id" label="name" v-model="selectedCategory"
                          :options="categories" placeholder="Category" class="v-style-chooser" />
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
            <template v-if="allowEdit">
                <b-form-group class="text-right">
                    <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                        Update
                    </b-button>
                </b-form-group>
            </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  import moment from "moment";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal", "city", "allowEdit"],
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        name: "",
        required, 
        categories: [],
        selectedCategory:'', 
        minimumCheckIn: null,
        minDate: null,
      };
    },
    async mounted() {
      if (this.showModal) {
        try{
          let currentDate = await this.getCurrentDate()
          this.minDate = await this.getPakistanDateString(currentDate);
          // let cityMinimumDateObj = new Date(this.city.minimum_booking_date + ' +05:00');

          let dateStringISO8601 = this.city.minimum_booking_date + ' +05:00'
          let parts = dateStringISO8601.split(' ');
          let datePart = parts[0]; // '2024-07-05'
          let timePart = parts[1]; // '00:00:00'
          let offsetPart = parts[2]; // '+05:00'
          let formattedDateString = datePart + 'T' + timePart + offsetPart;
          let cityMinimumDateObj = new Date(formattedDateString);

          let cityMinimumDate = await this.getPakistanDateString(cityMinimumDateObj);
          this.minimumCheckIn = cityMinimumDate
          const res = await this.getCityCategoriesUnpaginated();
          this.categories = res.data
          this.name = this.city.name
          for(let i=0; i<this.categories.length; i++){
            if(this.city.category == this.categories[i].id ){
              this.selectedCategory = this.categories[i]
            }      
          }
        }catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        getCityCategoriesUnpaginated: "appData/getCityCategoriesUnpaginated",
        updateBookingCity: "appData/updateBookingCity",
        fetchSystemTime: "appData/fetchSystemTime",
      }),
      async getCurrentDate() {
        await this.fetchSystemTime()
        var systemDateTime  = await this.getSystemTime;
        var currentDate = new Date(systemDateTime);
        return currentDate
      },
      async getPakistanDateString(date){
        date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
        var day = date.split("/")[1]
        var month = date.split("/")[0]
        var year = date.split("/")[2]
        return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
      },
      async validationForm() {
        const success = await this.$refs.cityEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let formData = new FormData();
          let dataToInsert = {
            name: this.name,
            category: this.selectedCategory.id,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
            minimum_booking_date: this.minimumCheckIn + ' 00:00:00',
          };
          formData.append("data", JSON.stringify(dataToInsert));
          const res = await this.updateBookingCity({
            payload: formData,
            pk: this.city.id,
          });

          if (res.status === 200) {
            this.$swal({
              title: "City updated successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("city-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ 
        getLoggedInUser: "appData/getUser",
        getSystemTime: "appData/getSystemTime",
      }),
    },
    watch: {
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
  